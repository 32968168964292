<template>
  <div class="passenger-visa-verification">
    <FetchPNRComponent
      @pnrFetched="handlePnrFetched"
      @resetData="handleResetData"
      @pnr="getPNR"
      @errorMessage="handleErrorMessage"
      @visa-verification-financial-profile-id="handleFinancialProfileId"
    />
    <MCard class="main-card" v-if="showSelectPassengerSection">
      <div class="margin-bottom-20">
        <MTypography type="headline">Select Passenger</MTypography>
        <MTypography type="body">
          Choose a passenger from the dropdown menu to initiate the visa and Ok
          To Board verification process.
        </MTypography>
      </div>
      <div class="passenger-selection">
        <MCombobox
          v-model:inputValue="selectedPassengerId"
          label="Select passenger for visa verification"
          itemValue="value"
          itemLabel="label"
          :options="availablePassengers"
          name="passengerSelect"
          class="select-passenger-combobox"
        />
        <MButton @click="addPassenger" :disabled="!selectedPassengerId"
          >Add Passenger</MButton
        >
      </div>
      <NewTravelerCardAccordion
        v-for="(passenger, index) in localPassengers"
        :key="index"
        :headline="renderAccordionHeadline(passenger.name)"
        :title="renderAccordionTitle(index)"
        :errorsLength="errorsLength(index)"
        :initialOpen="index === 0"
        :ref="`accordion-${index}`"
        class="passenger-accordion"
      >
        <div class="passenger-form-container">
          <PassengerVisaCard
            :passenger="passenger"
            :errors="localErrors[index]"
            @update:passenger="updatePassenger(index, $event)"
            @validate="(field:string, isValid:boolean) => validatePassenger(index, field, isValid)"
            @remove-passenger="removePassenger(index)"
            :ref="`passengerCard-${index}`"
          />
          <NewTravelerCardPreview
            title="Passenger Visa Details"
            :traveler="passenger"
            :previewFields="getPreviewFields()"
            :formatPreviewValue="formatPreviewValue"
            :errors="localErrors[index]"
          />
        </div>
        <div class="remove-passenger-btn">
          <MButton @click="removePassenger(index)">Remove Passenger</MButton>
        </div>
      </NewTravelerCardAccordion>
      <div class="submit-btn-container" v-if="localPassengers.length > 0">
        <MButton
          @click="handleSubmit"
          :disabled="isSubmitBtnLoading"
          class="submit-button"
          >Submit Application</MButton
        >
      </div>
    </MCard>
    <MCard v-if="hasErrorMessage">
      <MTypography type="body">{{ errorMessage }}</MTypography>
    </MCard>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MButton, MCard, MTypography, MCombobox } from "@aeroglobe/ag-core-ui";
import NewTravelerCardAccordion from "@/ag-flight-components/components/FlightBooking/NewTravelerAccordion.vue";
import NewTravelerCardPreview from "@/ag-flight-components/components/FlightBooking/NewTravelerPreview.vue";
import FetchPNRComponent from "./FetchPNRComponent.vue";
import PassengerVisaCard from "./PassengerVisaCard.vue";
import {
  PassengersList,
  PNRDetails,
  PNRDetailsV3,
} from "@/ag-flight-components/types";
import { Organization, USER_ROLES } from "@/modules/Auth/types";
import { AddVisaRequestDTO } from "../dto/addVisaRequest.dto";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import {
  UPLOAD_CONTENT_CATEGORY,
  UPLOAD_CONTENT_SUB_CATEGORY,
  UPLOAD_CONTENT_TYPE,
} from "@/modules/Organization/dtos/organization.dto";
import { PATH } from "@/ag-portal-common/constants/path";
import analyticServices from "@/analytic.service";
import { VISA_VERIFICATION_ANALYTICS_EVENTS } from "@/constants/analyticsEvents";
import UTILS from "@/ag-portal-common/utils";
import { de } from "date-fns/locale";

interface PassengerVisa {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  passportNumber: string;
  visaNumber: string;
  visaDocument: File | null;
  isInternational: boolean;
  country: string;
  uploadedVisaId?: string;
}

interface PassengersData {
  id: number;
  firstname: string;
  lastname: string;
}

export default defineComponent({
  name: "PassengerVisaVerificationPage",
  components: {
    MButton,
    MCard,
    MTypography,
    MCombobox,
    NewTravelerCardAccordion,
    NewTravelerCardPreview,
    FetchPNRComponent,
    PassengerVisaCard,
  },
  data() {
    return {
      errorMessage: "",
      selectedPassengerId: "",
      localPassengers: [] as PassengerVisa[],
      localErrors: [] as Record<string, string>[],
      currentErrorIndex: -1,
      allPassengers: [] as PassengersData[],
      PNR: "",
      financialProfileId: "",
    };
  },
  created() {
    this.handleResetData();
  },
  computed: {
    isAgentUser(): boolean {
      return UTILS.isUser(USER_ROLES.AGENT);
    },
    isFormValid(): boolean {
      return this.localErrors.every((error) => Object.keys(error).length === 0);
    },
    availablePassengers(): { label: string; value: string }[] {
      return this.allPassengers
        .filter(
          (passenger) =>
            !this.localPassengers.some(
              (localPassenger) => localPassenger.id === passenger.id
            )
        )
        .map((passenger) => ({
          label: `${passenger.firstname} ${passenger.lastname}`,
          value: passenger.id.toString(),
        }));
    },
    selectedPassenger(): PassengersData | undefined {
      return this.allPassengers.find(
        (passenger) => passenger.id === Number(this.selectedPassengerId)
      );
    },
    hasErrorMessage(): boolean {
      return !!this.errorMessage;
    },
    pnrDetails(): PNRDetails {
      return this.$store.getters.pnrDetails;
    },
    pnrDetailsV3(): PNRDetailsV3 {
      return this.$store.getters.pnrDetailsV3;
    },
    showSelectPassengerSection(): boolean {
      return (
        !this.hasErrorMessage &&
        (this.allPassengers?.length ?? 0) > 0 &&
        !!(this.pnrDetailsV3 || this.pnrDetails)
      );
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    isSubmitBtnLoading(): boolean {
      return this.$store.getters.isAddingRequestLoading;
    },
  },
  methods: {
    addPassenger() {
      const selectedPassenger = this.allPassengers.find(
        (p) => p.id.toString() === this.selectedPassengerId
      );
      if (selectedPassenger) {
        const nameParts = selectedPassenger.firstname.split(" ");
        const _lastName = nameParts.pop() || "";
        const _firstAndMiddleNames = nameParts.join(" ");

        const first_name = !selectedPassenger.lastname
          ? _firstAndMiddleNames
          : selectedPassenger.firstname;
        const last_name = !selectedPassenger.lastname
          ? _lastName
          : selectedPassenger.lastname;
        const newPassenger: PassengerVisa = {
          id: selectedPassenger.id,
          name: `${selectedPassenger.firstname} ${selectedPassenger.lastname}`,
          firstName: first_name,
          lastName: last_name,
          passportNumber: "",
          visaNumber: "",
          visaDocument: null,
          isInternational: true,
          country: "SA", // Default to Saudi Arabia
        };
        this.localPassengers.push(newPassenger);
        this.localErrors.push({});
        this.selectedPassengerId = ""; // Reset the selection
      }
    },
    removePassenger(index: number) {
      this.localPassengers.splice(index, 1);
      this.localErrors.splice(index, 1);
    },
    initializeErrors(passengersCount: number) {
      this.localErrors = Array(passengersCount)
        .fill(null)
        .map(() => ({}));
    },
    async handleSubmit() {
      this.validateAllPassengers();
      if (this.isFormValid) {
        try {
          const organizationId = this.isAgentUser
            ? this.organization?.organization_id
            : this.financialProfileId;

          const updatedPassengers = [];

          // Process each passenger sequentially
          for (const passenger of this.localPassengers) {
            if (passenger.visaDocument) {
              const uploadPayload = {
                content: passenger.visaDocument,
                content_category: UPLOAD_CONTENT_CATEGORY.DOCUMENTS,
                content_sub_category: UPLOAD_CONTENT_SUB_CATEGORY.VISA,
                content_type: UPLOAD_CONTENT_TYPE.PDF,
              };

              try {
                const [publicId, contentUrl] = await this.$store.dispatch(
                  "visaPDFUpload",
                  {
                    payload: uploadPayload,
                    organizationId,
                  }
                );

                if (!publicId) {
                  throw new Error(
                    `Failed to upload visa document for ${passenger.firstName} ${passenger.lastName}`
                  );
                }

                analyticServices.logActionEvent(
                  VISA_VERIFICATION_ANALYTICS_EVENTS.ADD_REQUEST
                );

                updatedPassengers.push({
                  ...passenger,
                  uploadedVisaId: publicId,
                });
              } catch (error) {
                updatedPassengers.push(passenger);
              }
            } else {
              updatedPassengers.push(passenger);
            }
          }

          // Filter passengers who have an uploaded visa
          const passengersWithUploadedVisa = updatedPassengers.filter(
            (passenger) => passenger.uploadedVisaId
          );

          if (passengersWithUploadedVisa.length === 0) {
            return;
          }

          // Prepare visa request payloads
          const visaRequestPayloads: AddVisaRequestDTO[] =
            passengersWithUploadedVisa.map((passenger) => ({
              pnr: this.PNR,
              first_name: passenger.firstName,
              last_name: passenger.lastName,
              visa_number: passenger.visaNumber,
              passport_number: passenger.passportNumber,
              uploaded_visa: passenger.uploadedVisaId,
              country: passenger.country,
              documents: {}, // Add any additional documents if needed
            }));

          // Submit all visa requests at once
          await this.$store.dispatch("addVisaRequests", {
            payload: visaRequestPayloads,
            organizationId,
            successHandler: () => {
              this.handleResetData();
              this.$router.push(PATH.VISA_VERIFICATION);
            },
          });
        } catch (error) {
          console.error("Error processing visa requests:", error);
        }
      } else {
        this.navigateToFirstError();
      }
    },
    validateAllPassengers() {
      this.localPassengers.forEach((_, index) => {
        const cardRef = this.$refs[`passengerCard-${index}`] as any;
        if (
          cardRef &&
          cardRef[0] &&
          typeof cardRef[0].validateAllFields === "function"
        ) {
          cardRef[0].validateAllFields();
        }
      });
      this.localPassengers.forEach((_, index) => {
        if (this.errorsLength(index) === 0) {
          this.closeAccordion(index);
        } else {
          this.openAccordion(index);
        }
      });
    },
    validatePassenger(index: number, field: string, isValid: boolean) {
      const passengerErrors = { ...this.localErrors[index] };
      if (isValid) {
        delete passengerErrors[field];
      } else {
        passengerErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required`;
      }
      this.localErrors = [
        ...this.localErrors.slice(0, index),
        passengerErrors,
        ...this.localErrors.slice(index + 1),
      ];
      if (
        Object.keys(passengerErrors).length === 0 &&
        index === this.currentErrorIndex
      ) {
        this.closeAccordion(index);
        this.navigateToNextError();
      }
    },
    errorsLength(index: number): number {
      return Object.keys(this.localErrors[index] || {}).length;
    },
    renderAccordionTitle(index: number): string {
      return `Passenger ${index + 1}`;
    },
    renderAccordionHeadline(name: string): string {
      return `Passenger Visa Details - ${name}`;
    },
    getPreviewFields() {
      return [
        { key: "firstName", label: "First Name" },
        { key: "lastName", label: "Last Name" },
        { key: "country", label: "Country" },
        { key: "passportNumber", label: "Passport Number" },
        { key: "visaNumber", label: "Visa Number" },
        { key: "visaDocument", label: "Visa Document" },
      ];
    },
    formatPreviewValue(key: string, value: any): string {
      if (!value) return "";
      if (key === "visaDocument") {
        return value.name || "";
      }
      if (key === "country") {
        return value === "SA" ? "Saudi Arabia" : value;
      }
      return value.toString();
    },
    navigateToFirstError() {
      const firstErrorIndex = this.localErrors.findIndex(
        (error) => Object.keys(error).length > 0
      );
      if (firstErrorIndex !== -1) {
        this.currentErrorIndex = firstErrorIndex;
        this.scrollToError(firstErrorIndex);
      }
    },
    navigateToNextError() {
      const nextErrorIndex = this.localErrors.findIndex(
        (error, index) =>
          index > this.currentErrorIndex && Object.keys(error).length > 0
      );
      if (nextErrorIndex !== -1) {
        this.currentErrorIndex = nextErrorIndex;
        this.scrollToError(nextErrorIndex);
      } else {
        this.currentErrorIndex = -1;
      }
    },
    scrollToError(passengerIndex: number) {
      this.openAccordion(passengerIndex);
      this.$nextTick(() => {
        const passengerCard = this.$refs[
          `passengerCard-${passengerIndex}`
        ] as any;
        if (
          passengerCard &&
          passengerCard[0] &&
          typeof passengerCard[0].scrollToFirstError === "function"
        ) {
          passengerCard[0].scrollToFirstError();
        }
      });
    },
    openAccordion(index: number) {
      const accordion = this.$refs[`accordion-${index}`] as any;
      if (
        accordion &&
        accordion[0] &&
        typeof accordion[0].open === "function"
      ) {
        accordion[0].open();
      }
    },
    closeAccordion(index: number) {
      const accordion = this.$refs[`accordion-${index}`] as any;
      if (
        accordion &&
        accordion[0] &&
        typeof accordion[0].close === "function"
      ) {
        accordion[0].close();
      }
    },
    updatePassenger(index: number, updatedPassenger: PassengerVisa) {
      this.localPassengers[index] = updatedPassenger;
    },
    handlePnrFetched(pnrDetails: PNRDetails) {
      const pnrDetailsV2 = this.$store.getters.pnrDetails;
      const pnrDetailsV3 = this.$store.getters.pnrDetailsV3;
      if (pnrDetailsV2 && pnrDetailsV2.passengers) {
        this.allPassengers = pnrDetails.passengers.map((passenger) => ({
          id: Date.now() + Math.random(), // Ensure unique IDs
          firstname: passenger.person_name,
          lastname: passenger.surname,
        }));
      } else if (
        pnrDetailsV3 &&
        pnrDetailsV3.booking_price_details[0] &&
        pnrDetailsV3.booking_price_details[0].passengers
      ) {
        let allPassengers =
          pnrDetailsV3.booking_price_details?.flatMap(
            (detail: {
              passengers: PassengersList[];
              is_ticketed: boolean;
              pnr_status: string;
            }) => {
              if (detail.is_ticketed && detail.pnr_status === "TICKETED") {
                return detail.passengers;
              }
              return [];
            }
          ) ?? [];

        const uniquePassengers = new Map();
        allPassengers.forEach(
          (passenger: { person_name: string; surname: string }) => {
            const fullName = `${passenger.person_name} ${passenger.surname}`;
            if (!uniquePassengers.has(fullName)) {
              uniquePassengers.set(fullName, passenger);
            }
          }
        );
        this.allPassengers = Array.from(uniquePassengers.values()).map(
          (passenger) => ({
            id: Date.now() + Math.random(),
            firstname: passenger.person_name,
            lastname: passenger.surname,
          })
        );
      }
    },
    handleResetData() {
      this.allPassengers = [];
      this.localPassengers = [];
      this.selectedPassengerId = "";
      this.localErrors = [];
      this.currentErrorIndex = -1;
      this.PNR = "";
      this.errorMessage = "";
      this.financialProfileId = "";
    },
    getPNR(pnr: string) {
      this.PNR = pnr;
    },
    handleErrorMessage(errorMessage: string) {
      this.errorMessage = errorMessage;
    },
    handleFinancialProfileId(financialProfileId: string) {
      this.financialProfileId = financialProfileId;
    },
  },
});
</script>

<style lang="scss" scoped>
.passenger-visa-verification {
  margin: 0 auto;

  .main-card {
    margin-bottom: 20px;
  }

  .passenger-selection {
    max-width: 600px;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
    .select-passenger-combobox {
      margin-bottom: 0px;
    }
  }

  .passenger-form-container {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    align-items: flex-start;
  }

  .submit-button {
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    .passenger-selection {
      flex-direction: column;
      align-items: stretch;
    }

    .passenger-form-container {
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
  }
}
.passenger-accordion {
  max-width: 1000px;
}
.submit-btn-container {
  display: flex;
  justify-content: flex-end;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.remove-passenger-btn {
  display: flex;
  justify-content: flex-end;
}
</style>
