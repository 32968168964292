import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1aa2a57c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "soa-content" }
const _hoisted_6 = { class: "soa-actions" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "invoices" }
const _hoisted_9 = { class: "accordion-title" }
const _hoisted_10 = { class: "mchip-alignment td-full-width" }
const _hoisted_11 = { class: "mchip-alignment td-full-width" }
const _hoisted_12 = { class: "accordion-title" }
const _hoisted_13 = { class: "mchip-alignment td-full-width" }
const _hoisted_14 = { class: "mchip-alignment td-full-width" }
const _hoisted_15 = { class: "accordion-title" }
const _hoisted_16 = { class: "mchip-alignment td-full-width" }
const _hoisted_17 = { class: "accordion-title" }
const _hoisted_18 = { class: "mchip-alignment td-full-width" }
const _hoisted_19 = { class: "mchip-alignment td-full-width" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MProgress = _resolveComponent("MProgress")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_ag_radio_item = _resolveComponent("ag-radio-item")!
  const _component_ag_radio = _resolveComponent("ag-radio")!
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_ag_checkbox = _resolveComponent("ag-checkbox")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_MFinancialProfileCombobox = _resolveComponent("MFinancialProfileCombobox")!
  const _component_MDatePicker = _resolveComponent("MDatePicker")!
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_AgNotFound = _resolveComponent("AgNotFound")!
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MChip = _resolveComponent("MChip")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MAccordion = _resolveComponent("MAccordion")!

  return (
      _ctx.$store.getters.isFetchingSectors ||
      _ctx.$store.getters.isFetchingFinancialProfiles ||
      _ctx.$store.getters.isFetchingOrganization
    )
    ? (_openBlock(), _createBlock(_component_MProgress, {
        key: 0,
        class: "soa-m-progess",
        indeterminate: ""
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MCard, { class: "soa-card" }, {
          default: _withCtx(() => [
            _createVNode(_component_MTypography, {
              type: "headline",
              class: "soa-heading"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Select Date Range")
              ]),
              _: 1
            }),
            (_ctx.showSelectByDropdown)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_MTypography, {
                    type: "body",
                    class: "soa-heading"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Whether to show Group or Individual? ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ag_radio, {
                    testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.RADIO_BUTTON, 'soa-selectBy'),
                    modelValue: _ctx.selectBy,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectBy) = $event)),
                    inline: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_radio_item, {
                        name: "selectBy",
                        testId: 
              _ctx.genTestId(
                _ctx.ELEMENT_TYPES.RADIO_BUTTON,
                `soa-selectBy-${_ctx.selectByOpts.group.value}`
              )
            ,
                        value: _ctx.selectByOpts.group.value,
                        label: _ctx.selectByOpts.group.label
                      }, null, 8, ["testId", "value", "label"]),
                      _createVNode(_component_ag_radio_item, {
                        name: "selectBy",
                        testId: 
              _ctx.genTestId(
                _ctx.ELEMENT_TYPES.RADIO_BUTTON,
                `soa-selectBy-${_ctx.selectByOpts.individual.value}`
              )
            ,
                        value: _ctx.selectByOpts.individual.value,
                        label: _ctx.selectByOpts.individual.label
                      }, null, 8, ["testId", "value", "label"])
                    ]),
                    _: 1
                  }, 8, ["testId", "modelValue"]),
                  (_ctx.selectBy === _ctx.selectByOpts.group.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_ag_radio, {
                          label: "Please select the sector",
                          modelValue: _ctx.selectedCity,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCity) = $event)),
                          inline: "",
                          testId: _ctx.genTestId(_ctx.ELEMENT_TYPES.RADIO_BUTTON, 'soa-selectedCity')
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cities, (city, index) => {
                              return (_openBlock(), _createBlock(_component_ag_radio_item, {
                                name: "selectedCity",
                                key: index,
                                testId: 
                _ctx.genTestId(
                  _ctx.ELEMENT_TYPES.RADIO_BUTTON,
                  `soa-selectedCity-${city.toLowerCase()}`
                )
              ,
                                value: city.toLowerCase(),
                                label: city
                              }, null, 8, ["testId", "value", "label"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "testId"])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.selectBy === _ctx.selectByOpts.group.value && _ctx.selectedCity)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_MTextfield, {
                          inputValue: _ctx.searchProfile,
                          "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchProfile) = $event)),
                          type: "text",
                          label: "Search Profile",
                          placeholder: "Search Profile"
                        }, null, 8, ["inputValue"]),
                        _createVNode(_component_ag_column, { class: "agency-scroll" }, {
                          default: _withCtx(() => [
                            (!_ctx.searchProfile)
                              ? (_openBlock(), _createBlock(_component_ag_checkbox, {
                                  key: 0,
                                  testId: 
                _ctx.genTestId(_ctx.ELEMENT_TYPES.CHECK_BOX, `soa-profiles-selectAll`)
              ,
                                  onClick: _ctx.onSelectAll,
                                  label: "Select all",
                                  modelValue: _ctx.checkSelectAllCheckBox,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.checkSelectAllCheckBox) = $event))
                                }, null, 8, ["testId", "onClick", "modelValue"]))
                              : _createCommentVNode("", true),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProfiles, (profile) => {
                              return (_openBlock(), _createBlock(_component_ag_checkbox, {
                                key: profile.public_id,
                                testId: 
                _ctx.genTestId(
                  _ctx.ELEMENT_TYPES.CHECK_BOX,
                  `soa-profiles-${profile.public_id}`
                )
              ,
                                modelValue: _ctx.selectedProfiles,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedProfiles) = $event)),
                                value: profile.public_id,
                                label: `${profile.financial_profile_name} - ${profile.platform_id}`
                              }, null, 8, ["testId", "modelValue", "value", "label"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.isGroupSelected)
              ? (_openBlock(), _createBlock(_component_MFinancialProfileCombobox, {
                  key: 1,
                  class: "fp-combo",
                  inputValue: _ctx.financialProfilePublicId,
                  "onUpdate:inputValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.financialProfilePublicId) = $event)),
                  label: "Financial Profiles",
                  itemValue: "value",
                  itemLabel: "label",
                  disabled: _ctx.isFinancialProfileFetching,
                  options: _ctx.financialProfileOptions
                }, null, 8, ["inputValue", "disabled", "options"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_MDatePicker, {
                label: "Start Date",
                startDate: _ctx.minStartDate,
                "max-date": new Date(),
                "min-date": _ctx.minStartDate,
                onOnRangeDateChange: _ctx.changeStartDate,
                placeholder: "Check In Date",
                dateType: "startDate",
                multiCalendar: false,
                hasRange: false,
                autoPosition: false,
                position: "bottom",
                class: "soa-dates"
              }, null, 8, ["startDate", "max-date", "min-date", "onOnRangeDateChange"]),
              _createVNode(_component_MDatePicker, {
                label: "End Date",
                startDate: _ctx.endDate,
                "min-date": _ctx.startDate,
                "max-date": _ctx.maxDate,
                onOnRangeDateChange: _ctx.changeEndDate,
                placeholder: "End Date",
                dateType: "endDate",
                multiCalendar: false,
                hasRange: false,
                class: "soa-dates",
                autoPosition: false,
                position: "bottom",
                error: _ctx.soaDateError
              }, null, 8, ["startDate", "min-date", "max-date", "onOnRangeDateChange", "error"]),
              _createVNode(_component_MCombobox, {
                inputValue: _ctx.sortBy,
                "onUpdate:inputValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sortBy) = $event)),
                options: _ctx.items,
                itemValue: "value",
                itemLabel: "label",
                label: "Sort By"
              }, null, 8, ["inputValue", "options"]),
              _createElementVNode("div", _hoisted_6, [
                _withDirectives(_createVNode(_component_MButton, {
                  onClick: _ctx.downloadSOA,
                  disabled: 
              _ctx.disableButtons ||
              _ctx.$store.getters.isDownloadingSOA ||
              !_ctx.hasAnySelectedField
            ,
                  class: "soa-action-buttons"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Download PDF ")
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"]), [
                  [_vShow, !_ctx.isGroupSelected]
                ]),
                _withDirectives(_createVNode(_component_MButton, {
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.sendSOAEmail())),
                  disabled: 
              _ctx.disableButtons ||
              _ctx.$store.getters.isSendingSOA ||
              !_ctx.hasAnySelectedField
            ,
                  class: "soa-action-buttons"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Send Email ")
                  ]),
                  _: 1
                }, 8, ["disabled"]), [
                  [_vShow, _ctx.isUserFinance || _ctx.isUserAgSuper]
                ])
              ])
            ])
          ]),
          _: 1
        }),
        (_ctx.isUserAgent)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_MCard, null, {
                default: _withCtx(() => [
                  _createVNode(_component_MTypography, { type: "headline" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Pending Receipts and Invoices")
                    ]),
                    _: 1
                  }),
                  (
            _ctx.pendingPostingFlightInvoices.length === 0 &&
            _ctx.pendingPostingFlightReceipts.length === 0 &&
            _ctx.pendingPostingHotelInvoices.length === 0 &&
            _ctx.pendingPostingGroupFlightInvoices.length === 0
          )
                    ? (_openBlock(), _createBlock(_component_AgNotFound, {
                        key: 0,
                        "test-id": "",
                        heading: "No Pending Receipts & Invoices Found",
                        description: ""
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_8, [
                (_ctx.pendingPostingFlightInvoices.length > 0)
                  ? (_openBlock(), _createBlock(_component_MAccordion, {
                      key: 0,
                      "initial-open": true
                    }, {
                      title: _withCtx(() => [
                        _createElementVNode("span", _hoisted_9, [
                          _createVNode(_component_AgIconInfoBar, {
                            "test-id": "",
                            mPrepandIcon: "m-receipt-long",
                            title: "Flight Invoices",
                            class: "accordion-title soa-accordion"
                          })
                        ])
                      ]),
                      content: _withCtx(() => [
                        _createVNode(_component_MDataTable, {
                          headers: _ctx.flightInvoicesHeaders,
                          data: _ctx.pendingPostingFlightInvoices,
                          "item-per-page": 10,
                          "total-item-count": _ctx.pendingPostingFlightInvoices.length,
                          "has-search": false,
                          "is-api-paginated": false
                        }, {
                          ticket_no: _withCtx(({ item }) => [
                            _createVNode(_component_MTypography, {
                              class: "description",
                              type: "body"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.ticket_no), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          pnr: _withCtx(({ item }) => [
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(_component_MChip, {
                                class: "chip-width",
                                "border-less": true,
                                variant: "warning"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.pnr), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]),
                          posting_status: _withCtx(({ item }) => [
                            _createElementVNode("div", _hoisted_11, [
                              _createVNode(_component_MChip, {
                                class: "chip-width",
                                "border-less": true,
                                variant: "error"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.posting_status), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]),
                          _: 1
                        }, 8, ["headers", "data", "total-item-count"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pendingPostingFlightReceipts.length > 0)
                  ? (_openBlock(), _createBlock(_component_MAccordion, {
                      key: 1,
                      "initial-open": true
                    }, {
                      title: _withCtx(() => [
                        _createElementVNode("span", _hoisted_12, [
                          _createVNode(_component_AgIconInfoBar, {
                            "test-id": "",
                            mPrepandIcon: "m-receipt-long",
                            title: "Flight Receipts",
                            class: "accordion-title soa-accordion"
                          })
                        ])
                      ]),
                      content: _withCtx(() => [
                        _createVNode(_component_MDataTable, {
                          headers: _ctx.flightReceiptsHeaders,
                          data: _ctx.pendingPostingFlightReceipts,
                          "item-per-page": 10,
                          "has-search": false,
                          "is-api-paginated": false
                        }, {
                          payment_id: _withCtx(({ item }) => [
                            _createVNode(_component_MTypography, {
                              class: "description",
                              type: "body"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.payment_id), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          amount: _withCtx(({ item }) => [
                            _createElementVNode("div", _hoisted_13, [
                              _createVNode(_component_MChip, {
                                class: "chip-width",
                                "border-less": true,
                                variant: "warning"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.amount), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]),
                          posting_status: _withCtx(({ item }) => [
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(_component_MChip, {
                                class: "chip-width",
                                "border-less": true,
                                variant: "error"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.posting_status), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]),
                          _: 1
                        }, 8, ["headers", "data"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pendingPostingHotelInvoices.length > 0)
                  ? (_openBlock(), _createBlock(_component_MAccordion, {
                      key: 2,
                      "initial-open": true
                    }, {
                      title: _withCtx(() => [
                        _createElementVNode("span", _hoisted_15, [
                          _createVNode(_component_AgIconInfoBar, {
                            "test-id": "",
                            mPrepandIcon: "m-receipt-long",
                            title: "Hotel Invoices",
                            class: "accordion-title soa-accordion"
                          })
                        ])
                      ]),
                      content: _withCtx(() => [
                        _createVNode(_component_MDataTable, {
                          headers: _ctx.hotelInvoicesHeaders,
                          data: _ctx.pendingPostingHotelInvoices,
                          "item-per-page": 10,
                          "has-search": false,
                          "is-api-paginated": false
                        }, {
                          booking_id: _withCtx(({ item }) => [
                            _createVNode(_component_MTypography, {
                              class: "description",
                              type: "body"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.booking_id), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          status: _withCtx(({ item }) => [
                            _createElementVNode("div", _hoisted_16, [
                              _createVNode(_component_MChip, {
                                class: "chip-width",
                                "border-less": true,
                                variant: "error"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.status), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]),
                          _: 1
                        }, 8, ["headers", "data"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.pendingPostingGroupFlightInvoices.length > 0)
                  ? (_openBlock(), _createBlock(_component_MAccordion, {
                      key: 3,
                      "initial-open": true
                    }, {
                      title: _withCtx(() => [
                        _createElementVNode("span", _hoisted_17, [
                          _createVNode(_component_AgIconInfoBar, {
                            "test-id": "",
                            mPrepandIcon: "m-receipt-long",
                            title: "Group Flight Invoices",
                            class: "accordion-title soa-accordion"
                          })
                        ])
                      ]),
                      content: _withCtx(() => [
                        _createVNode(_component_MDataTable, {
                          headers: _ctx.groupFlightInvoicesHeaders,
                          data: _ctx.pendingPostingGroupFlightInvoices,
                          "item-per-page": 10,
                          "has-search": false,
                          "is-api-paginated": false
                        }, {
                          booking_id: _withCtx(({ item }) => [
                            _createVNode(_component_MTypography, {
                              class: "description",
                              type: "body"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.booking_id), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          posting_status: _withCtx(({ item }) => [
                            _createElementVNode("div", _hoisted_18, [
                              _createVNode(_component_MChip, {
                                class: "chip-width",
                                "border-less": true,
                                variant: "error"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.posting_status), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]),
                          price: _withCtx(({ item }) => [
                            _createElementVNode("div", _hoisted_19, [
                              _createVNode(_component_MChip, {
                                class: "chip-width",
                                "border-less": true,
                                variant: "warning"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.price), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]),
                          _: 1
                        }, 8, ["headers", "data"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
}