import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63b19e7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "issuance-inputs" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MProgress = _resolveComponent("MProgress")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MFinancialProfileCombobox = _resolveComponent("MFinancialProfileCombobox")!
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_MTextfield = _resolveComponent("MTextfield")!
  const _component_m_button = _resolveComponent("m-button")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_ctx.isFinancialProfileFetching)
    ? (_openBlock(), _createBlock(_component_MProgress, {
        key: 0,
        indeterminate: "",
        class: "mprogress-alignment"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MCard, { class: "visa-verification-input-form" }, {
          default: _withCtx(() => [
            _createVNode(_component_MTypography, { type: "headline" }, {
              default: _withCtx(() => [
                _createTextVNode("Fetch PNR")
              ]),
              _: 1
            }),
            _createVNode(_component_MTypography, {
              type: "body",
              class: "margin-bottom-20"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Ensure the PNR has been ticketed and includes at least one flight segment within Saudi Arabia. ")
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_MFinancialProfileCombobox, {
                inputValue: _ctx.flightInformation.financialProfile.value,
                "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.flightInformation.financialProfile.value) = $event)),
                label: "Financial Profiles",
                itemValue: "value",
                itemLabel: "label",
                hasError: _ctx.flightInformation.financialProfile.hasError,
                errorMessage: _ctx.flightInformation.financialProfile.errorMessage,
                disabled: _ctx.isPnrFetched || _ctx.isFetchingPNR,
                options: _ctx.financialProfileItems
              }, null, 8, ["inputValue", "hasError", "errorMessage", "disabled", "options"]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_MCombobox, {
                  inputValue: _ctx.flightInformation.supplier.value,
                  "onUpdate:inputValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.flightInformation.supplier.value) = $event)),
                  options: _ctx.suppliers,
                  itemValue: "value",
                  itemLabel: "label",
                  label: "Airline/Supplier",
                  hasError: _ctx.flightInformation.supplier.hasError,
                  errorMessage: _ctx.flightInformation.supplier.errorMessage,
                  disabled: 
              _ctx.isPnrFetched || _ctx.isFetchingPNR || !_ctx.isAirlinesAndProvidersFetched
            
                }, null, 8, ["inputValue", "options", "hasError", "errorMessage", "disabled"]),
                _createVNode(_component_MTextfield, {
                  inputValue: _ctx.flightInformation.pnrNumber.value,
                  "onUpdate:inputValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.flightInformation.pnrNumber.value) = $event)),
                  inputValueModifiers: { trim: true },
                  label: "PNR Number",
                  hasError: _ctx.flightInformation.pnrNumber.hasError,
                  errorMessage: _ctx.flightInformation.pnrNumber.errorMessage,
                  disabled: _ctx.isPnrFetched || _ctx.isFetchingPNR
                }, null, 8, ["inputValue", "hasError", "errorMessage", "disabled"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                (_ctx.isSabreSelected)
                  ? (_openBlock(), _createBlock(_component_MCombobox, {
                      key: 0,
                      inputValue: _ctx.flightInformation.sabreAirline.value,
                      "onUpdate:inputValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.flightInformation.sabreAirline.value) = $event)),
                      options: _ctx.sabreAirlinesComboboxOptions,
                      itemValue: "value",
                      itemLabel: "label",
                      label: "Sabre Airline",
                      hasError: _ctx.flightInformation.sabreAirline.hasError,
                      errorMessage: _ctx.flightInformation.sabreAirline.errorMessage,
                      disabled: 
                _ctx.isPnrFetched || _ctx.isFetchingPNR || !_ctx.isAirlinesAndProvidersFetched
              
                    }, null, 8, ["inputValue", "options", "hasError", "errorMessage", "disabled"]))
                  : _createCommentVNode("", true),
                (_ctx.isGalileoSelected)
                  ? (_openBlock(), _createBlock(_component_MCombobox, {
                      key: 1,
                      inputValue: _ctx.flightInformation.galileoAirline.value,
                      "onUpdate:inputValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.flightInformation.galileoAirline.value) = $event)),
                      options: _ctx.galileoAirlinesComboboxOptions,
                      itemValue: "value",
                      itemLabel: "label",
                      label: "Galileo Airline",
                      hasError: _ctx.flightInformation.galileoAirline.hasError,
                      errorMessage: _ctx.flightInformation.galileoAirline.errorMessage,
                      disabled: 
                _ctx.isPnrFetched || _ctx.isFetchingPNR || !_ctx.isAirlinesAndProvidersFetched
              
                    }, null, 8, ["inputValue", "options", "hasError", "errorMessage", "disabled"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_m_button, {
                class: "issuance-action",
                disabled: !_ctx.canFetchPnrDetails || _ctx.isPnrFetched,
                type: "filled",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.canFetchPnrDetails ? _ctx.handleFetchPnrDetails() : ''))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.isFetchingPNR ? "Fetching PNR" : "Get PNR Details"), 1)
                ]),
                _: 1
              }, 8, ["disabled"]),
              _createVNode(_component_m_button, {
                class: "issuance-action",
                disabled: _ctx.infoHasErrors() || !_ctx.isPnrFetched,
                type: "outlined",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.infoHasErrors() || !_ctx.isPnrFetched ? '' : _ctx.handleDataReset()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Reset ")
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _withDirectives(_createVNode(_component_MTypography, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.sabreAirlineInfoMessage), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.showSabreAirlineInfoMessage]
            ]),
            _withDirectives(_createVNode(_component_MTypography, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.galileoAirlineInfoMessage), 1)
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.showGalileoAirlineInfoMessage]
            ])
          ]),
          _: 1
        })
      ]))
}