import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50a7476c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "passenger-visa-verification" }
const _hoisted_2 = { class: "margin-bottom-20" }
const _hoisted_3 = { class: "passenger-selection" }
const _hoisted_4 = { class: "passenger-form-container" }
const _hoisted_5 = { class: "remove-passenger-btn" }
const _hoisted_6 = {
  key: 0,
  class: "submit-btn-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FetchPNRComponent = _resolveComponent("FetchPNRComponent")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MCombobox = _resolveComponent("MCombobox")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_PassengerVisaCard = _resolveComponent("PassengerVisaCard")!
  const _component_NewTravelerCardPreview = _resolveComponent("NewTravelerCardPreview")!
  const _component_NewTravelerCardAccordion = _resolveComponent("NewTravelerCardAccordion")!
  const _component_MCard = _resolveComponent("MCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FetchPNRComponent, {
      onPnrFetched: _ctx.handlePnrFetched,
      onResetData: _ctx.handleResetData,
      onPnr: _ctx.getPNR,
      onErrorMessage: _ctx.handleErrorMessage,
      onVisaVerificationFinancialProfileId: _ctx.handleFinancialProfileId
    }, null, 8, ["onPnrFetched", "onResetData", "onPnr", "onErrorMessage", "onVisaVerificationFinancialProfileId"]),
    (_ctx.showSelectPassengerSection)
      ? (_openBlock(), _createBlock(_component_MCard, {
          key: 0,
          class: "main-card"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_MTypography, { type: "headline" }, {
                default: _withCtx(() => [
                  _createTextVNode("Select Passenger")
                ]),
                _: 1
              }),
              _createVNode(_component_MTypography, { type: "body" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Choose a passenger from the dropdown menu to initiate the visa and Ok To Board verification process. ")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_MCombobox, {
                inputValue: _ctx.selectedPassengerId,
                "onUpdate:inputValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPassengerId) = $event)),
                label: "Select passenger for visa verification",
                itemValue: "value",
                itemLabel: "label",
                options: _ctx.availablePassengers,
                name: "passengerSelect",
                class: "select-passenger-combobox"
              }, null, 8, ["inputValue", "options"]),
              _createVNode(_component_MButton, {
                onClick: _ctx.addPassenger,
                disabled: !_ctx.selectedPassengerId
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Add Passenger")
                ]),
                _: 1
              }, 8, ["onClick", "disabled"])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localPassengers, (passenger, index) => {
              return (_openBlock(), _createBlock(_component_NewTravelerCardAccordion, {
                key: index,
                headline: _ctx.renderAccordionHeadline(passenger.name),
                title: _ctx.renderAccordionTitle(index),
                errorsLength: _ctx.errorsLength(index),
                initialOpen: index === 0,
                ref_for: true,
                ref: `accordion-${index}`,
                class: "passenger-accordion"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_PassengerVisaCard, {
                      passenger: passenger,
                      errors: _ctx.localErrors[index],
                      "onUpdate:passenger": ($event: any) => (_ctx.updatePassenger(index, $event)),
                      onValidate: (field, isValid) => _ctx.validatePassenger(index, field, isValid),
                      onRemovePassenger: ($event: any) => (_ctx.removePassenger(index)),
                      ref_for: true,
                      ref: `passengerCard-${index}`
                    }, null, 8, ["passenger", "errors", "onUpdate:passenger", "onValidate", "onRemovePassenger"]),
                    _createVNode(_component_NewTravelerCardPreview, {
                      title: "Passenger Visa Details",
                      traveler: passenger,
                      previewFields: _ctx.getPreviewFields(),
                      formatPreviewValue: _ctx.formatPreviewValue,
                      errors: _ctx.localErrors[index]
                    }, null, 8, ["traveler", "previewFields", "formatPreviewValue", "errors"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_MButton, {
                      onClick: ($event: any) => (_ctx.removePassenger(index))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Remove Passenger")
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ])
                ]),
                _: 2
              }, 1032, ["headline", "title", "errorsLength", "initialOpen"]))
            }), 128)),
            (_ctx.localPassengers.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_MButton, {
                    onClick: _ctx.handleSubmit,
                    disabled: _ctx.isSubmitBtnLoading,
                    class: "submit-button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Submit Application")
                    ]),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.hasErrorMessage)
      ? (_openBlock(), _createBlock(_component_MCard, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_MTypography, { type: "body" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}